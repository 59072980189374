import React, { ReactNode } from 'react'

interface Props {
  children: ReactNode
}

function Section(props: Props) {
  return <section className="py-8">{props.children}</section>
}

export { Section }
