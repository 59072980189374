import React from 'react'
import { graphql } from 'gatsby'
import classNames from 'classnames'

import { Layout } from '../components/Layout'
import { Hero } from '../components/Hero'
import { SEO } from '../components/SEO'
import { PracticeAreaBox } from '../components/PracticeAreaBox'
import { Section } from '../components/Section'
import { Container } from '../components/Container'

import { PracticeArea } from '../interfaces'

interface Props {
  data: {
    practiceAreas: {
      nodes: Array<PracticeArea>
    }
  }
}

function Index(props: Props) {
  const practiceAreas = props.data.practiceAreas.nodes

  return (
    <Layout>
      <SEO title="Hem" />

      <Hero />

      <Section>
        <h2 className="font-serif text-center text-2xl md:text-4xl text-gray-800">Verksamhetsområden</h2>

        <Container>
          <div className="md:flex pt-8">
            {practiceAreas.map((area, idx) => (
              <div
                key={idx}
                className={classNames('max-w-xs mx-auto shadow', {
                  'mt-6 md:mt-0': idx !== 0,
                })}
              >
                <PracticeAreaBox
                  title={area.title}
                  preamble={area.preamble}
                  backgroundColor={area.associatedColor?.hex}
                  slug={area.slug}
                />
              </div>
            ))}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default Index

export const pageQuery = graphql`
  {
    practiceAreas: allDatoCmsPracticeArea {
      nodes {
        slug
        title
        preamble
        associatedColor {
          hex
        }
      }
    }

    backgroundImage: file(relativePath: { eq: "stockholm.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
