import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

function Hero() {
  const data = useStaticQuery(graphql`
    {
      backgroundImage: file(relativePath: { eq: "stockholm.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage fluid={data.backgroundImage.childImageSharp.fluid} style={{ backgroundPosition: 'center 70%' }}>
      <div className="pt-40 pb-40 text-center text-white uppercase px-8 sm:pb-48 md:pb-64">
        <h1 className="font-serif font-bold text-xl sm:text-2xl md:text-4xl border-t-2 border-b-2 inline-block px-3 py-1">
          Advokatfirman Sievo AB
        </h1>
        <h5 className="pt-4 tracking-widest">Stockholm</h5>
      </div>
    </BackgroundImage>
  )
}

export { Hero }
