import React from 'react'
import { Link } from 'gatsby'
import { FaChevronRight } from 'react-icons/fa'

import { PracticeArea } from '../../interfaces'

interface Props extends PracticeArea {
  backgroundColor?: string
}

function PracticeAreaBox(props: Props) {
  return (
    <Link
      to={`/verksamhetsomraden/${props.slug}`}
      style={{ backgroundColor: props.backgroundColor }}
      className="p-4 text-white flex flex-col text-center justify-between h-full"
    >
      <div className="mb-4">
        <h3 className="mb-3 font-serif text-2xl">{props.title}</h3>
        <p>{props.preamble}</p>
      </div>
      <p className="flex items-center justify-end">
        Läs mer <FaChevronRight className="pl-2 relative" style={{ top: 1 }} />
      </p>
    </Link>
  )
}

export { PracticeAreaBox }
